window.checkUpdates = function () {
    return $('form#sales-order').serialize() !== $('#_cached').val();
};

window.validatePS = function() {
    console.log('validate PS');
    let valid = parseFloat($('#actual-order-total').val());
    let ps = JSON.parse($('input[name="order_schedule"]').val());
    let total = 0;
    for (let s of ps) {
        total += parseFloat(s.value);
    }

    if (total === valid) {
        $('.schedule-counter').val(ps.length + ' invoice(s)');
        $('.btn-xero').show();
        $('.alert-row').html('');
    } else {
        $('.btn-xero').hide();
        $('.schedule-counter').val(ps.length + ' invoice(s)');
        $('.alert-row').html(`<div class="alert alert-warning">
            <small>The Payment Schedule does <strong>NOT</strong> cover the order total. Please adjust the Payment Schedule or select a different Invoice Type</small>
        </div>`);
    }
};

$(function () {

    require('./sub/create-xero-contact');

    $('.validate-href').on('click', function() {
        if (checkUpdates()) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-dark me-2',
                    cancelButton: 'btn btn-dark me-2',
                    denyButton: 'btn btn-success me-2',
                },
                buttonsStyling: false,
            })

            swalWithBootstrapButtons.fire({
                title: 'Changes found..',
                text: "You have unsaved changes to your Sales Order.\n\nYou should Update the Sales Order first.",
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: false,
                showDenyButton: true,
                cancelButtonText: 'Close',
                denyButtonText: 'Update'
            }).then((result) => {
                if (result.isConfirmed) {

                } else if (result.isDenied) {
                    $('#sales-order button.submit').trigger('click');
                }
            })

        } else {
            window.location = $(this).data('href');
        }
    })

    if ($('.xero-selector').length > 0) {
        $('.xero-selector').select2({
            ajax: {
                url: '/query/xero/contacts/' + $('.xero-account-selector').data('tenant'),
                dataType: 'json'
            }
        });
    }

    if ($('#xero-contact-lookup').length > 0) {

        $('.invoice-options .xero-sync-toggle *').hide();

        $.ajax({
            url: '/query/xero/lookup/' + $('#xero-contact-lookup').data('tenant') + '/' + $('#xero-contact-lookup').val(),
            type: 'get',
            success: function (result) {
                if (result.error) {
                    $('.invoice-options .xero-submit').prop('disabled', true).css('pointer-events', 'none').css('opacity', '.5');
                    $('.xero-lookup-output').html(`<strong>Lookup Error</strong><small style="color:#cc0000"><small>${result.error}</small></small>`);
                } else {
                    $('.xero-lookup-output').html(`<strong>${result.Name}</strong><br/><small><small>[${result.ContactID}]</small></small>`)
                }

            },
            error: function () {

            }
        });
    }

    $("#check-all").click(function () {
        $(this).parents('table').find('input[type="checkbox"]').not(':disabled').prop('checked', $(this).prop('checked'));
    });

    /* Make the fields readonly when loading old() data */
    if ($('#select-account-type').val() != 'new') {
        $('.contact-information input').prop('readonly', true)
    }

    if ($('#xeroDatePicker').length) {
        var date = new Date();
        var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        $('#xeroDatePicker').datepicker({
            format: "dd/mm/yyyy",
            autoclose: true,
            /*defaultViewDate: [
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
            ]*/
        }).on('changeDate', function (e) {

            var tag = e.format();
            if (typeof e.date != 'undefined' && $('#invoice-type').val() != 'single') {

                if (!$('#xeroDateTags').tagExist(tag)) {
                    $('#xeroDateTags').addTag(tag);
                }
                //$('#xeroDatePicker').datepicker('clearDates');

            }
        });

        //$('#xeroDatePicker').datepicker('setDate', today);

        $('#invoice-method').on('change', function () {
            if ($(this).val() == 'item') {
                $('.xero-check').prop('checked', true).prop('disabled', true);
                $('.xero-package-row').hide();
                $('.do-transparent').removeClass('is-transparent');
                $('#invoice-type').val('single').trigger('change').addClass('readonly');
            } else {
                $('.xero-check:not(.perm)').prop('disabled', false);
                $('.xero-package-row').show();
                $('.do-transparent').addClass('is-transparent');
                $('#invoice-type').removeClass('readonly');
            }
        }).trigger('change');

        $('#invoice-type').on('change', function () {

            if ($(this).val() == 'schedule') {

                $('.order-add-schedule').show();
                $('#xeroDatePicker input').val('').prop('disabled', true);
                $('.row.multi-dates').css('display', 'none');

                validatePS();
            } else {
                $('.row.multi-dates').css('display', $(this).val() == 'multiple' ? 'flex' : 'none');
                $('.row.single-date').css('display', $(this).val() == 'single' ? 'flex' : 'none');

                $('.alert-row').html('');
                $('.btn-xero').show();

                if ($(this).val() === 'multiple') {
                    validatePS();
                }
            }

        }).trigger('change');

        $('#xeroDateTags').tagsInput({
            'width': '100%',
            'height': '38px',
            'tagClass': 'xero',
            'interactive': false,
            'removeWithBackspace': false,
            'minChars': 0,
            'maxChars': 20,
            'placeholderColor': '#666666'
        });

        window.disableInvoicing = function () {

            $('form.create-invoice').removeClass('invoiceable').addClass('invoiced');
            $('form.create-invoice.invoiced').find('input,select,button').prop('disabled', true);
            $('form.create-invoice.invoiced').find('button').hide();
            // handle the schedule so we can see it //
            $('form.create-invoice.invoiced').find('button.order-add-schedule').prop('disabled',false).show();
            $('#orderPaymentSchedule .add-schedule').hide();

            $('form.create-invoice .invoice_item').addClass('disabled-clear');

            $('#orderPaymentSchedule table input, #orderPaymentSchedule table button').prop('disabled', true);
            $('#orderPaymentSchedule tfoot').hide();
        };

        require('./sub/submit-invoice');
        require('./sub/invoice-handler');

        if ($('form.create-invoice').hasClass('invoiced')) {
            disableInvoicing();
            checkInvoiceUpdates();
        }
    }

    let schedule_total = 0;
    let order_total = 0;

    $('.schedule-template').on('change', function() {
        let val = $(this).val();
        if (val !== '') {

            $('#orderPaymentSchedule .removable').remove();
            $('#orderPaymentSchedule .schedule-date, #orderPaymentSchedule .schedule-amount').val('');

            let each = ( Math.ceil( (order_total / val) * 20) / 20).toFixed(2);

            for (let i = 0; i < val - 1; i++) {
                $('.add-schedule-row').trigger('click');
            }

            let final = (order_total - (each * (val - 1))).toFixed(2);

            $('#orderPaymentSchedule .schedule-amount').val(each);
            $('#orderPaymentSchedule tbody tr:nth-child(' + (val) +') .schedule-amount').val(final);

            $(this).val('');

        }
    })

    $('.order-add-schedule').on('click', function (e) {
        order_total = parseFloat($('#actual-order-total').val()).toFixed(2);
        $('#orderPaymentSchedule .modal-footer h6 span').text(order_total);

        let schedule = $('input[name="order_schedule"]').val();
        if (schedule.length !== 0) {
            schedule = JSON.parse(schedule);
            let c = 0;
            for (let i in schedule) {
                let row = $('#orderPaymentSchedule table > tbody > tr').get(i);
                if (typeof row === 'undefined') {
                    scheduleRow();
                    row = $('#orderPaymentSchedule table > tbody > tr').get(i);
                }
                $(row).find('.schedule-date').val(schedule[i].date);
                $(row).find('.schedule-amount').val(schedule[i].value);
            }

            $('.schedule-amount').trigger('keyup');

            if ($('form.create-invoice').hasClass('invoiced')) {
                disableInvoicing();
            }
        }
        $('#orderPaymentSchedule').modal('show');
    });

    if ($('#orderPaymentSchedule').length > 0) {
        $('#orderPaymentSchedule .datepicker').datepicker({format: "dd/mm/yyyy", autoclose: true});
    }

    $('.add-schedule').on('click', function (e) {
        let arr = []; let err = 0;
        $('#orderPaymentSchedule table tbody tr').each(function () {
            let date = $(this).find('.schedule-date').val();
            let amount = $(this).find('.schedule-amount').val();
            if (amount.length > 0 && date.length > 0) {
                arr.push({'date': date, 'value': amount});
            } else {
                err++;
            }
        });

        if (err > 0) {
            alert('Please enter a date and price for each scheduled slot.');
            return;
        }

        $('input[name="order_schedule"]').val(JSON.stringify(arr));

        if ($('form.create-invoice').length) {
            validatePS();
        } else {
            $('#sales-order button.submit').trigger('click');
        }

        $('#orderPaymentSchedule .removable').remove();
        //$('#orderPaymentSchedule form')[0].reset();
        $('#orderPaymentSchedule').modal('hide');
    });

    $('.add-schedule-row').on('click', function () {
        scheduleRow();
    });

    function scheduleRow() {
        $('#orderPaymentSchedule table tbody').append(`<tr class="removable">
            <td>
                <div class="input-group input-group-sm date datepicker">
                    <input type="text" class="form-control form-control-sm schedule-date">
                    <span class="input-group-text input-group-addon"><i data-feather="calendar"></i></span>
                </div>
            </td>
            <td><input type="number" class="form-control form-control-sm text-right schedule-amount" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" value=""></td>
            <td><button type="button" class="btn btn-danger btn-icon btn-xs remove-row">
                <i class="link-arrow" data-feather="trash-2"></i>
            </button></td>
        </tr>`);

        $('#orderPaymentSchedule .datepicker').datepicker({
            format: "dd/mm/yyyy",
            autoclose: true
        });
        feather.replace();
    }

    $('body').on('keyup', '.schedule-amount', function () {

        schedule_total = 0;
        $('.schedule-amount').each(function () {
            schedule_total += parseFloat($(this).val() === '' ? 0 : $(this).val());
        });

        $('#orderPaymentSchedule .modal-footer h6 span').text(parseFloat(order_total - schedule_total).toFixed(2));
    });

    $('body').on('click', '.remove-row', function () {
        $(this).parents('tr').remove();
        $('.schedule-amount').trigger('keyup');
    });

    require('./sub/sales-order-emails');
    require('./sub/product-handler');

    $('.send-note').on('click', function () {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
            }
        });

        $(this).prop('disabled', true);

        let $data = {
            'sales_order_id' : $(this).data('order'),
            'note' : tinymce.get("salesOrderNoteTextarea").getContent(),
        };

        $.ajax({
            url: "/engagements/note",
            type: 'post',
            dataType: 'json',
            data: $data,
            success: function (result) {

                tinymce.get("salesOrderNoteTextarea").setContent("");

                $('[name="_token"]').attr('content', result.token);
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': result.token
                    }
                });

                $('.send-note').prop('disabled', false);
                $('ul.chat-list').append(result.content);

                $(".log-output").scrollTop($(".log-output").prop("scrollHeight"));
            },
            error: function (xhr, status, error) {
                console.log(xhr);
            }
        });
    });

    // Fix for the silly bug in select-2
    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
    });

    $('body').on('click', '.edition-dropdown a', function(e) {
        e.preventDefault(); e.stopPropagation();
        $(this).find('input').prop('checked', !$(this).find('input').is(':checked')).trigger('change');
    });

    $('body').on('change', '.edition-dropdown a input', function() {
        const parent = $(this).parents('.edition-dropdown');
        let count = parent.find('input:checked').length;
        parent.find('button').text(count === 0 ? 'Select Edition(s)' : count + ' selected');

        if (count > 0 && !$(this).parents('tr').find('input.will-add').is(':checked')) {
            $(this).parents('tr').find('input.will-add').prop('checked', true).trigger('change');
        } else if (count === 0) {
            $(this).parents('tr').find('input.will-add').prop('checked', false).trigger('change');
        }
    });

    $('#orderProductSelection').on('show.bs.modal', function () {
		$('.edition-dropdown input[type="checkbox"]').prop('checked', false).trigger('change');
        $('#orderProductMonthSelection input[type="checkbox"]').prop('checked', false).trigger('change');
	});

    $('#field-editor').on('click', function() {
        $('.contact-information .row input').prop('readonly', !$(this).is(':checked'))
    });

    setTimeout(function() {
        $('#_cached').val($('form#sales-order').serialize());
    }, 100);

});

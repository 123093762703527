$('.create-invoice .btn-xero').on('click', function (e) {

    let errors = [];

    if ($('#select-xero').val() === '') {
        errors.push(' - You must select a Xero Account to assign this Invoice');
    }

    if ($('#payment-type').val() === '') {
        errors.push(' - You must select a Payment type for this Invoice');
    }

    if ($('input.invoice_item').val() === '') {
        errors.push(' - Line Item requires a value');
    }

    let terms = parseInt($('input[name="invoice_terms"]').val());
    if (isNaN(terms) || terms <= 0) {
        errors.push(' - Invoice Terms requires a value greater than 0');
    }

    if ($('select[name="invoice_type"]').val() === 'single' && $('input[name="invoice_date"]').val() === '') {
        errors.push(' - An Invoice Date is required.');
    } else if ($('select[name="invoice_type"]').val() === 'multiple' && $('input[name="invoice_dates"]').val() === '') {
        errors.push(' - Invoice Dates are required.');
    }

    if (errors.length === 0) {
        // Make it //
        $('.invoice-card .card-loader-wrapper').addClass('card-loader-active');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
            }
        });

        $.ajax({
            url: "/manage/xero/invoice",
            type: 'post',
            dataType: 'json',
            data: $('.create-invoice').serialize(),
            success: function (result) {

                $('meta[name="csrf-token"]').attr('content', result.token);

                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': result.token
                    }
                });

                // Now we need to filter the errors to make sure its all good //

                disableInvoicing();
                $('.invoice-card .card-loader-wrapper').removeClass('card-loader-active');

                if (result.errors.length === 0) {
                    for (const row of result.table) {
                        $('.invoice-list table tbody').append($(row));
                    }
                } else {
                    $('.invoice-list table tbody').append($(result.errors));
                }

                $('.invoice-list').removeClass('invoice-list-hidden');
                feather.replace();

            },
            error: function (xhr, status, error) {
                // This will be general errors //
            }
        });

    } else {
        alert("Please correct the following errors:\n\n" + errors.join("\n"));
    }
});

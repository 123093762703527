// Modal Stuff //

let xero_names = [];

$('select.xero-selector option').each(function () {
    xero_names.push($(this).data('contact'));
});

let validateXeroName = function () {
    let name = $('#xero-contact-name');
    return xero_names.indexOf(name.val().toLowerCase()) === -1;
};

let xeroCreateContact = function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    let $data = $("form#xeroContactForm").serialize();

    $('#newXeroContact button, #newXeroContact select, #newXeroContact input').prop('disabled', true);
    $('#newXeroContact').addClass('ajaxSaving');

    $.ajax({
        url: "/manage/xero/contact",
        type: 'post',
        dataType: 'json',
        data: $data,
        success: function (result) {

            $('meta[name="csrf-token"]').attr('content', result.token);
            $('#newXeroContact').removeClass('ajaxSaving');

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': result.token
                }
            });

            // Re-enable all the buttons //
            $('#newXeroContact button, #newXeroContact select, #newXeroContact input').prop('disabled', false);

            // Now we need to filter the errors to make sure its all good //
            if (result.errors.length === 0) {
                $('#newXeroContact').modal('hide');

                let opt = $(result.option);
                opt.prop('selected', true);

                let new_option = `<option data-params="${result.option.params}" value="${result.option.id}">${result.option.name}</option>`;
                $('#select-account').append(new_option).val(result.option.id).trigger('change');

            } else {
                // Handle all the errors //
                alert(result.errors.join("\n"));
            }

        },
        error: function (xhr, status, error) {
            // This will be general errors //
        }
    });
};

$('#newXeroContact .btn-close, #newXeroContact .btn-secondary').on('click', function () {
    $('.xero-selector').val('').trigger('change');
});

$('#newXeroContact').on('hidden.bs.modal', function () {

    $('#newXeroContact button, #newXeroContact select, #newXeroContact input').prop('disabled', false);
    $('#newXeroContact select, #newXeroContact input').each(function () {
        if ($(this).data('default')) {
            $(this).val($(this).data('default'));
        }
    });

}).on('shown.bs.modal', function () {
    console.log('showing');
    document.getElementById('xero-contact-name').focus();
});

$('#xero-contact-name').on('blur', function (e) {
    //setTimeout(function() {
    console.log(e);
    console.log(e.relatedTarget.dataset['bsDismiss']);
    if (!e.relatedTarget || (e.relatedTarget && !e.relatedTarget.dataset['bsDismiss'])) {
        if (!validateXeroName()) {
            $('#xeroContactExists').modal('toggle');
        }
    }
    //}, 250);
});

$('#xeroContactForm .required-field').on('blur', function (e) {
    if ($(this).val() === '') {
        $(this).addClass('required-error');
    } else {
        $(this).remove('required-error');
    }
});

$('#xeroContactExists button.btn').on('click', function () {
    if ($(this).hasClass('xero-change-name')) {
        $('#xero-contact-name').select().focus();
    } else {

        let selected = $('select.xero-selector option[data-contact="' + $('#xero-contact-name').val().toLowerCase() + '"]').attr('value');
        $('.xero-selector').val(selected).trigger('change');

        $('#newXeroContact input, #newXeroContact select').each(function () {
            $(this).val($(this).data('default')).removeClass('required-error');
        });

        $('#newXeroContact').modal('hide');
    }

    $('#xeroContactExists').modal('hide');
});

$('.xero-create').on('click', function (e) {
    if (validateXeroName()) {

        $('#newXeroContact .required-field').removeClass('required-error');
        $('#newXeroContact .required-field').each(function () {
            if ($(this).val() == '') {
                $(this).addClass('required-error');
            }
        });

        if ($('#newXeroContact .required-error').length === 0) {
            xeroCreateContact();
        }
    } else {
        // Name needs to be unique to Xero //=
    }
});

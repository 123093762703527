let packageItems = [];

let package_print_item = function($this) {

    let editions = $this.parents('tr').find('.edition-dropdown a > input:checked');

    if (editions.length === 0) {
        return false;
    } else {
        editions.each(function() {

            let option = $(this); //(edition.val() === 'current' ? edition.find('option.current') : edition.find('option[value="' + edition.val() + '"]'));
            let msrp = option.data('price') ?? $this.data('msrp');
            let loading = $this.parents('tr').find('select.loading > option:selected');

            packageItems.push({
                id: $this.data('id'),
                name: $this.data('name'),
                publication: $this.data('publication'),
                edition_id: option.attr('value'),
                edition_name: option.data('label'),
                loading: {
                    name: loading.data('name'),
                    rate: loading.data('rate'),
                },
                msrp: msrp,
                price: msrp,
                future: 0
            });
        });
    }
    return true;
};

let package_digital_item = function($this) {

    let editions = $this.parents('tr').find('.edition-months-selected');
    let empty = 0;

    $this.parents('tr').find('.edition-months-selected').each(function() {

        let option = $(this);

        if ($(this).val() === '') {
            empty++;
        } else {

            let msrp = option.data('price') ?? $this.data('msrp');
            let loading = $this.parents('tr').find('select.loading > option:selected');
            let count = $(this).val().split(',');

            packageItems.push({
                id: $this.data('id'),
                name: $this.data('name'),
                publication: $this.data('publication'),
                edition_id: option.data('edition'),
                edition_name: option.data('label'),
                loading: {
                    name: loading.data('name'),
                    rate: loading.data('rate'),
                },
                itemdata: $(this).val(),
                msrp: msrp,
                price: msrp,
                future: 0
            });
        }

    });

    return editions !== empty;
};

$(function () {

	if ($('#package-type').length > 0) {

		let products_selected = 0;

		$('#package-type').prop('disabled', $('.package-data-table tbody tr').length > 0);
		update_package_prices();

		$('.packageModal').on('hidden.bs.modal', function () {
			products_selected = 0;
			$('.packageModal .modal-footer h6 > span').text(0);
			$('#' + $(this).attr('id') + ' form')[0].reset();

            $('.edition-months-selected').val('');
            $('.edition-months').text('Select Month(s)');
		});

        $('#customProductSelection').on('show.bs.modal', function () {
            $('.edition-dropdown input[type="checkbox"]').prop('checked', false).trigger('change');
            $('#customProductSelection input[type="checkbox"]').prop('checked', false).trigger('change');
        });

		$('.package-add-product').on('click', function () {
			if ($('#package-type').val() === 'custom') {
				$('#customProductSelection').modal('show');
			} else {
				$('#autoProductSelection').modal('show');
			}
		});

		$('.package-data-table').on('click', 'button.btn-danger', function (e) {
			e.preventDefault();
			$(this).parents('tr').remove();

			if ($('.package-data-table tbody tr').length === 0) {
				$('#package-type').prop('disabled', false);
			}

			update_package_prices();
		});

		$('.package-data-table').on('keyup', 'input.product-price', function (e) {
			update_package_prices();
		});

		$('#package-type').on('change', function () {

			$('input[name="package_type"]').val($(this).val());

			if ($(this).val() === 'custom') {
				$('#subtract-msrp').prop('disabled', true).prop('checked', true);
				$('#package-total').prop('readonly', true);
			} else {
				$('#subtract-msrp').prop('checked', true).prop('disabled', true); // false
				$('#package-total').prop('readonly', true); // false
			}
		}).trigger('change');

		$('.packageModal .will-add').on('change', function () {
			products_selected = $('.packageModal .will-add:checked').length;
			$('.packageModal .modal-footer h6 > span').text(products_selected);
		});

		$('#customProductSelection .add-items').on('click', function () {
			if (products_selected > 0) {

				let errors = 0;

				$('#customProductSelection .will-add:checked').each(function () {

                    if ($(this).hasClass('is-print')) {

                        if (!package_print_item($(this))) {
                            errors++;
                        }

                    } else {
                        if (!package_digital_item($(this))) {
                            errors++;
                        }
                    }
				});

				if (errors === 0) {

					for (let item of packageItems) {
						add_to_package(item, 'custom');
					}

                    packageItems = [];

					$('#package-type').prop('disabled', true);
					$('#customProductSelection').modal('toggle');

				} else {
					alert('Please make sure all selected products have an edition assigned');
				}
			} else {
                alert('no products');
            }

		});

		$('#autoProductSelection .add-items').on('click', function () {
			if (products_selected > 0) {

				let errors = 0;
				let items = [];

				$('#autoProductSelection .will-add:checked').each(function () {

					let futures = $(this).parents('tr').find('input.edition').val();

					add_to_package({
						id: $(this).data('id'),
						name: $(this).data('name'),
						futures: parseInt(futures),
						publication: $(this).data('publication'),
						msrp: $(this).data('msrp'),
						price: $(this).data('msrp')
					}, 'auto');
				});

				$('#package-type').prop('disabled', true);
				$('#autoProductSelection').modal('toggle');
			}

		});
	}
});

let add_to_package = function (data, add_type) {

	let count = $('.package-data-table tr').length;

	if (add_type === 'custom') {

        /*console.log(data);

        let price = parseFloat(data.price ?? data.msrp);

        if (data.loading.rate !== 0) {
            price += parseFloat(price * (data.loading.rate / 100));
        }

		let input = `<input type="number" class="form-control text-right product-price" name="products[${count}][product_price]" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" value="${price.toFixed(2)}">`;
        input += `<input type="hidden" name="products[${count}][product_id]" value="${data.id}">`
		input += `<input type="hidden" name="products[${count}][edition_id]" value="${data.edition_id}">`
		input += `<input type="hidden" name="products[${count}][product_options][type]" value="custom">`
		input += `<input type="hidden" name="products[${count}][product_options][label]" value="${data.name}">`
		input += `<input type="hidden" name="products[${count}][product_options][publication]" value="${data.publication}">`
		input += `<input type="hidden" name="products[${count}][product_options][edition]" value="${data.edition_name}">`
		input += `<input type="hidden" name="products[${count}][product_options][default_price]" value="${data.price ?? data.msrp}" class="default-price">`
        input += `<input type="hidden" name="products[${count}][product_options][loading][name]" value="${data.loading.name}">`
		input += `<input type="hidden" name="products[${count}][product_options][loading][rate]" value="${data.loading.rate}">`

		$('.package-data-table tbody').append(`<tr>
			<td>${data.publication}<br/><em class="text-muted"><small>${data.edition_name}</small></em></td>
	        <td>${data.name}<br/><em class="text-muted"><small>${data.loading.name} ${data.loading.rate > 0 ? '(' + data.loading.rate + '%)' : ''}</small></em></td>
	        <td class="text-right">${data.msrp}</td>
	        <td class="text-right">${input}</td>
	        <td class="text-right">
	            <div class="btn-group">
	                <button type="button" class="btn btn-danger btn-icon btn-sm">
	                    <i class="link-arrow" data-feather="trash-2"></i>
	                </button>
	            </div>
	        </td>
	    </tr>`);*/

        //let count = $('.order-data-table tr.line-item').length;

        let input = `<input type="hidden" name="products[${count}][product_id]" value="${data.id}">`

        input += `<input type="hidden" name="products[${count}][edition_id]" value="${data.edition_id}">`
        input += `<input type="hidden" name="products[${count}][product_options][type]" value="custom">`
		input += `<input type="hidden" name="products[${count}][product_options][label]" value="${data.name}">`
		input += `<input type="hidden" name="products[${count}][product_options][publication]" value="${data.publication}">`
		input += `<input type="hidden" name="products[${count}][product_options][edition]" value="${data.edition_name}">`
        input += `<input type="hidden" name="products[${count}][product_options][default_price]" value="${data.price ?? data.msrp}" class="default-price">`
        input += `<input type="hidden" name="products[${count}][product_options][loading][name]" value="${data.loading.name}">`
        input += `<input type="hidden" name="products[${count}][product_options][loading][rate]" value="${data.loading.rate}">`;


        let price = parseFloat(data.price ?? data.msrp);
        let name = data.name;

        if (data.itemdata) {

            let months = data.itemdata.split(',');

            input += `<input type="hidden" name="products[${count}][product_options][item_data]" value="${data.itemdata}">`;
            name += ` x ${months.length}<br/><em class="text-muted"><small>${months.join(', ')}</small></em>`;

            price = price * months.length;
        }

        if (data.loading.rate !== 0) {
            price += parseFloat(price * (data.loading.rate / 100));
        }

        input += `<input type="number" class="form-control text-right product-price" name="products[${count}][product_price]" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" value="${price.toFixed(2)}">`;

        $('.package-data-table tbody').append(`<tr>
			<td>${data.publication}<br/><em class="text-muted"><small>${data.edition_name}</small></em></td>
	        <td>${name}<br/><em class="text-muted"><small>Loading: ${data.loading.name} ${data.loading.rate > 0 ? '(' + data.loading.rate + '%)' : ''}</small></em></td>
	        <td class="text-right"><input class="form-control" readonly value="${data.msrp}" style="text-align: right"></td>
	        <td class="text-right">${input}</td>
	        <td class="text-right">
	            <div class="btn-group">
	                <button type="button" class="btn btn-danger btn-icon btn-sm">
	                    <i class="link-arrow" data-feather="trash-2"></i>
	                </button>
	            </div>
	        </td>
	    </tr>`);

	} else {

		let input = `<div class="input-group">
			<input type="number" class="form-control text-right product-price" name="products[${data.id}][product_price]" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" value="${data.price ?? data.msrp}">
			<div class="input-group-addon">
				<small class="input-group-text">each</small>
			</div>
		</div>`;
		input += `<input type="hidden" name="products[${data.id}][product_options][type]" value="auto">`
		input += `<input type="hidden" name="products[${data.id}][product_options][label]" value="${data.name}">`
		input += `<input type="hidden" name="products[${data.id}][product_options][futures]" value="${data.futures}" class="package-futures">`
		input += `<input type="hidden" name="products[${data.id}][product_options][publication]" value="${data.publication}">`
		input += `<input type="hidden" name="products[${data.id}][product_options][default_price]" value="${data.price ?? data.msrp}" class="default-price">`

		$('.package-data-table tbody').append(`<tr>
			<td>${data.publication}<br/><em class="text-muted"><small>Current Edition</small></em></td>
	        <td>${data.name}<br/><em class="text-muted"><small>+ ${data.futures} future edition(s)</small></em></td>
	        <td class="text-right"><input class="form-control" readonly value="${data.msrp}" style="text-align: right"></td>
	        <td class="text-right">${input}</td>
	        <td class="text-right">
	            <div class="btn-group">
	                <button type="button" class="btn btn-danger btn-icon btn-sm">
	                    <i class="link-arrow" data-feather="trash-2"></i>
	                </button>
	            </div>
	        </td>
	    </tr>`);
	}

	feather.replace();
	update_package_prices();
};

let update_package_prices = function () {
	let total = 0;
	let value = 0;

	console.log('updating');

	$('table.package-data-table tbody > tr').each(function () {
		if (!$(this).hasClass('deleted')) {

			let multiplier = 1;

			if ($('#package-type').val() === 'auto') {
				multiplier += parseInt($(this).find('input.package-futures').val());
			}

			console.log($('#package-type').val());

			value += parseFloat($(this).find('input.default-price').val()) * multiplier;
			total += parseFloat($(this).find('input.product-price').val()) * multiplier;
		}
	});

	$('#package-total').val(total.toFixed(2));
	$('#package-value').val(value.toFixed(2));
};

let add_to_order = function (table, data, packed) {

	let count = $(table + ' tr.line-item').length;

	let input = `<input type="hidden" name="items[${count}][product_id]" value="${data.id}">
        <input type="hidden" name="items[${count}][product_name]" value="${data.name}">
		<input type="hidden" name="items[${count}][edition_id]" value="${data.edition_id}">
		<input type="hidden" name="items[${count}][edition_future]" value="${data.future}">
		<input type="hidden" name="items[${count}][loading_name]" value="${data.loading.name}">
		<input type="hidden" name="items[${count}][loading_rate]" value="${data.loading.rate}">`;

    let price = parseFloat(data.price ?? data.msrp);
    let name = data.name;

    if (table === 'table.contract-data-table' && $('#salesOrderContractModal .account-select').val() == 1) {
        price = 0;
    }

    if (data.itemdata && data.itemdata != '') {

        let months = data.itemdata.split(',');

        input += `<input type="hidden" name="items[${count}][item_data]" value="${data.itemdata}">`;
        name += ` x ${months.length}<br/><em class="text-muted months-selected" data-bs-toggle="tooltip" data-bs-placement="top" title="${months.join(', ')}"><small>${months.join(', ')}</small></em>`;

        price = price * months.length;
    }

    if (data.loading.rate !== 0) {
        price += parseFloat(price * (data.loading.rate / 100));
    }

	input += `<input type="number" class="form-control text-right product-price" data-msrp="${data.msrp}" name="items[${count}][product_price]" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" value="${price.toFixed(2)}">`;

    let discount = `<input type="number" class="form-control form-control-sm text-right product-discount" step="1" data-msrp="${data.msrp}" name="items[${count}][product_discount]" pattern="[0-9]+([\.,][0-9]+)?" value="0">`;
    let gst = `<input type="number" class="form-control form-control-sm text-right product-price-gst" readonly pattern="[0-9]+([\.,][0-9]+)?" value="${(price * .1).toFixed(2)}">`
    let final = `<input type="number" class="form-control form-control-sm text-right product-price-total" step="0.01" pattern="[0-9]+([\.,][0-9]+)?" value="${(price * 1.1).toFixed(2)}">`

	if (packed) {
        packed.original = data.options;
		input += `<input type="hidden" name="items[${count}][package_data]" value="${JSON.stringify(packed).replace(/"/g, '\&quot;')}">`
	}

	$(table + ' tbody').append(`<tr class="line-item">
		<td>${data.publication}<br/><em class="text-muted"><small>${data.edition_name}</small></em></td>
        <td>${name}</td>
        <td>${data.loading.name} ${data.loading.rate > 0 ? '(' + data.loading.rate + '%)' : ''}</td>
        <td class="text-right">${data.msrp}</td>
        <td class="text-right">${discount}</td>
        <td class="text-right">${input}</td>
        <td class="text-right">${gst}</td>
        <td class="text-right">${final}</td>
        <td width="50" class="text-right">
            <div class="btn-group">
                <button type="submit" class="btn btn-danger btn-icon btn-xs">
                    <i class="link-arrow" data-feather="trash-2"></i>
                </button>
            </div>
        </td>
    </tr>`);

    if (table === 'table.contract-data-table' && $('#salesOrderContractModal .account-select').val() == 1) {
        $('#salesOrderContractModal').find('tr.line-item input.product-discount, tr.line-item input.product-price, tr.line-item input.product-price-total').prop('readonly', true);
    }

	setTimeout(function() {
        feather.replace();
    },300);
	update_order_prices(table,0);
};

let update_order_prices = function (table, with_gst) {

	let sub = 0, gst = 0, total = 0;
    let row_sub = 0, row_gst = 0, row_total = 0;

	$(table + ' tbody > tr.line-item').each(function () {
		if (!$(this).hasClass('deleted')) {

            if (with_gst !== 1) {
                row_sub = parseFloat($(this).find('input.product-price').val());
                row_gst = row_sub * .1, row_total = row_sub * 1.1;

                $(this).find('.product-price-gst').val(row_gst.toFixed(2));
                $(this).find('.product-price-total').val(row_total.toFixed(2));
            } else {
                row_total = parseFloat($(this).find('input.product-price-total').val());
                row_sub = (row_total / 1.1);
                row_gst = (row_total - row_sub);

                $(this).find('.product-price').val(row_sub.toFixed(2));
                $(this).find('.product-price-gst').val(row_gst.toFixed(2));
            }

            sub += parseFloat(row_sub);
            gst += parseFloat(row_gst);
            total += parseFloat(row_total);
		}
	});

    if ($(table + ' tbody > tr.line-item').length === 0) {
        $(table + ' .empty-table').show();
    } else {
        $(table + ' .empty-table').hide();
    }

	$(table + ' #order-total').val(sub.toFixed(2));
	$(table + ' #order-gst').val(gst.toFixed(2));
    $(table + ' #order-final').val(total.toFixed(2));
};

let salesOrderItems = [];
let salesOrderPackageItems = [];

let add_print_item = function($this) {

    let editions = $this.parents('tr').find('input.edition-option:checked');

    if (editions.length === 0) {
        return false;
    } else {
        editions.each(function() {

            let option = $(this); //(edition.val() === 'current' ? edition.find('option.current') : edition.find('option[value="' + edition.val() + '"]'));
            let msrp = option.data('price') ?? $this.data('msrp');
            let loading = $this.parents('tr').find('select.loading > option:selected');

            salesOrderItems.push({
                id: $this.data('id'),
                name: $this.data('name'),
                publication: $this.data('publication'),
                edition_id: option.attr('value'),
                edition_name: option.data('label'),
                loading: {
                    name: loading.data('name'),
                    rate: loading.data('rate'),
                },
                msrp: msrp,
                price: msrp,
                future: 0
            });
        });
    }
    return true;
};

let add_digital_item = function($this) {

    let editions = $this.parents('tr').find('.edition-months-selected').length;
    let empty = 0;

    $this.parents('tr').find('.edition-months-selected').each(function() {

        let option = $(this);

        if ($(this).val() === '') {
            empty++;
        } else {

            let msrp = option.data('price') ?? $this.data('msrp');
            let loading = $this.parents('tr').find('select.loading > option:selected');
            let count = $(this).val().split(',');

            salesOrderItems.push({
                id: $this.data('id'),
                name: $this.data('name'),
                publication: $this.data('publication'),
                edition_id: option.data('edition'),
                edition_name: option.data('label'),
                loading: {
                    name: loading.data('name'),
                    rate: loading.data('rate'),
                },
                itemdata: $(this).val(),
                msrp: msrp,
                price: msrp,
                future: 0
            });
        }

    });

    return editions !== empty;
};

if ($('.order-add-product').length > 0) {

	let products_selected = 0;

	update_order_prices('table.order-data-table', 0);

	$('.orderModal').on('hidden.bs.modal', function () {
		products_selected = 0;
		$('.orderModal .modal-footer h6 > span').text(0);
		$('#' + $(this).attr('id') + ' form')[0].reset();

        $('.edition-months-selected').val('');
        $('.edition-months').text('Select Month(s)');
	});

	$('.order-add-product').on('click', function () {
		$('#orderProductSelection').modal('show');
	});

	$('.order-data-table').on('click', 'button.btn-danger', function (e) {
		e.preventDefault();
		$(this).parents('tr').remove();

		if ($('.order-data-table tbody tr').length === 0) {
			$('#order-type').prop('disabled', false);
		}

		update_order_prices('table.order-data-table', 0);
	});

    $('.order-data-table').on('keyup', 'input.product-price-total', function (e) {
		update_order_prices('table.order-data-table', 1);
	});

	$('.order-data-table').on('keyup', 'input.product-price', function (e) {
		update_order_prices('table.order-data-table', 0);
	});

    $('.order-data-table').on('keyup', 'input.product-discount', function (e) {

        const discount_percentage = parseInt($(this).val());
        let msrp = parseFloat($(this).data('msrp'));
        let price = msrp;

        let discount = parseFloat(msrp * (discount_percentage / 100));

        if (discount_percentage > 0) {
            price = msrp - discount;
        }

        $(this).parents('tr').find('.product-price').val(price.toFixed(2));

        update_order_prices('table.order-data-table', 0);
    });

	$('#order-type').on('change', function () {

		$('input[name="order_type"]').val($(this).val());

		if ($(this).val() === 'custom') {
			$('#subtract-msrp').prop('disabled', true).prop('checked', true);
			$('#order-total').prop('readonly', true);
		} else {
			$('#subtract-msrp').prop('checked', true).prop('disabled', true); // false
			$('#order-total').prop('readonly', true); // false
		}N
	}).trigger('change');

	$('.orderModal .will-add').on('change', function () {
		products_selected = $('.orderModal .will-add:checked').length;
		$('.orderModal .modal-footer h6 > span').text(products_selected);
	});

	$('#orderProductSelection .add-items').on('click', function () {
		if (products_selected > 0) {

			let errors = 0;

			$('#orderProductSelection .will-add:checked').each(function () {

                let $this = $(this);

				if ($(this).hasClass('is-product')) {
                    if ($(this).hasClass('is-print') || $(this).hasClass('as-print')) {

                        if (!add_print_item($(this))) {
                            console.log('add_print_item_error');
                            errors++;
                        }

                    } else {
                        if (!add_digital_item($(this))) {
                            console.log('add_digital_item_error');
                            errors++;
                        }
                    }

				} else {
					// Packages //
					$.get('/query/products/byPackage/' + $(this).data('id'), function (data) {
						for (let product of data.products) {
							add_to_order('table.order-data-table', {
								id: product.id,
								name: product.name,
								publication: product.publication,
								edition_id: product.edition_id,
								edition_name: product.edition_name,
								msrp: product.msrp,
								price: product.product_price,
								future: product.future,
								options: product.options,
                                itemdata: product.options.item_data ?? null,
                                loading: product.options.loading
							}, data.package);
						}
					}, 'json');
				}
			});

			if (errors === 0) {

				for (let item of salesOrderItems) {
					add_to_order('table.order-data-table', item);
				}

                salesOrderItems = [];

				$('#order-type').prop('disabled', true);
				$('#orderProductSelection').modal('toggle');

			} else {
				alert('Please make sure all selected products have an edition assigned');
                salesOrderItems = [];
                salesOrderPackageItems = [];
			}
		}

	});
}


if ($('.contract-add-product').length > 0) {

    let products_selected = 0;

    update_order_prices('table.contract-data-table', 0);

    $('.orderModal').on('hidden.bs.modal', function () {
        products_selected = 0;
        $('.orderModal .modal-footer h6 > span').text(0);
        $('#' + $(this).attr('id') + ' form')[0].reset();

        $('.edition-months-selected').val('');
        $('.edition-months').text('Select Month(s)');
    });

    $('.contract-add-product').on('click', function () {
        $('#contractProductSelection').modal('show');
    });

    $('.contract-data-table').on('click', 'button.btn-danger', function (e) {
        e.preventDefault();
        $(this).parents('tr').remove();

        if ($('.contract-data-table tbody tr').length === 0) {
            $('#order-type').prop('disabled', false);
        }

        update_order_prices('table.contract-data-table', 0);
    });

    $('.contract-data-table').on('keyup', 'input.product-price-total', function (e) {
        update_order_prices('table.contract-data-table', 1);
    });

    $('.contract-data-table').on('keyup', 'input.product-price', function (e) {
        update_order_prices('table.contract-data-table', 0);
    });

    $('.contract-data-table').on('keyup', 'input.product-discount', function (e) {

        const discount_percentage = parseInt($(this).val());
        let msrp = parseFloat($(this).data('msrp'));
        let price = msrp;

        let discount = parseFloat(msrp * (discount_percentage / 100));

        if (discount_percentage > 0) {
            price = msrp - discount;
        }

        $(this).parents('tr').find('.product-price').val(price.toFixed(2));

        update_order_prices('table.contract-data-table', 0);
    });

    $('#order-type').on('change', function () {

        $('input[name="order_type"]').val($(this).val());

        if ($(this).val() === 'custom') {
            $('#subtract-msrp').prop('disabled', true).prop('checked', true);
            $('#order-total').prop('readonly', true);
        } else {
            $('#subtract-msrp').prop('checked', true).prop('disabled', true); // false
            $('#order-total').prop('readonly', true); // false
        }N
    }).trigger('change');

    $('.orderModal .will-add').on('change', function () {
        products_selected = $('.orderModal .will-add:checked').length;
        $('.orderModal .modal-footer h6 > span').text(products_selected);
    });

    $('#contractProductSelection .add-items').on('click', function () {
        if (products_selected > 0) {

            let errors = 0;

            $('#contractProductSelection .will-add:checked').each(function () {

                let $this = $(this);

                if ($(this).hasClass('is-product')) {
                    if ($(this).hasClass('is-print') || $(this).hasClass('as-print')) {

                        if (!add_print_item($(this))) {
                            console.log('add_print_item_error');
                            errors++;
                        }

                    } else {
                        if (!add_digital_item($(this))) {
                            console.log('add_digital_item_error');
                            errors++;
                        }
                    }

                } else {
                    // Packages //
                    $.get('/query/products/byPackage/' + $(this).data('id'), function (data) {
                        for (let product of data.products) {
                            add_to_order('table.contract-data-table', {
                                id: product.id,
                                name: product.name,
                                publication: product.publication,
                                edition_id: product.edition_id,
                                edition_name: product.edition_name,
                                msrp: product.msrp,
                                price: product.product_price,
                                future: product.future,
                                options: product.options,
                                itemdata: product.options.item_data ?? null,
                                loading: product.options.loading
                            }, data.package);
                        }
                    }, 'json');
                }
            });

            if (errors === 0) {

                for (let item of salesOrderItems) {
                    add_to_order('table.contract-data-table', item);
                }

                salesOrderItems = [];

                $('#order-type').prop('disabled', true);
                $('#contractProductSelection').modal('toggle');

            } else {
                alert('Please make sure all selected products have an edition assigned');
                salesOrderItems = [];
                salesOrderPackageItems = [];
            }
        }

    });
}

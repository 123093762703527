$(function() {
    $('.create-contact').on('click', function() {
       $('#hubspotCreateContact').modal('show');
    });

    $('#hubspotCreateContact .confirm-create').on('click', function() {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
            }
        });

        let $data = $("#hubspotCreateContact form").serialize();

        $('#hubspotCreateContact button, #hubspotCreateContact select, #hubspotCreateContact input').prop('disabled', true);
        $('#hubspotCreateContact').addClass('ajaxSaving');
        $('#hubspotCreateContact .saving-contact').removeClass('hidden');

        $.ajax({
            url: "/manage/hubspot/contact",
            type: 'post',
            dataType: 'json',
            data: $data,
            success: function (result) {

                $('meta[name="csrf-token"]').attr('content', result.token);
                $('#newXeroContact').removeClass('ajaxSaving');

                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': result.token
                    }
                });

                // Re-enable all the buttons //
                $('#hubspotCreateContact button, #hubspotCreateContact select, #hubspotCreateContact input').prop('disabled', false);

                $('#hubspotCreateContact').removeClass('ajaxSaving');
                $('#hubspotCreateContact .saving-contact').addClass('hidden');

                // Now we need to filter the errors to make sure its all good //
                if (typeof result.response.error === 'undefined') {

                    $('#hubspotCreateContact').modal('hide');
                    $('#hubspotCreateContact select, #hubspotCreateContact input').val('');

                    $('input[name="hubspot_contact_id"]').val(result.response.id);

                    for (const property in result.response.properties) {
                        $('.contact-information [data-hs="' + property + '"]').val(result.response.properties[property]);
                    }

                } else {
                    // Handle all the errors //
                    alert(result.response.error);
                }

            },
            error: function (xhr, status, error) {
                // This will be general errors //
            }
        });
    });
});

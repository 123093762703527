window.checkInvoiceUpdates = function() {



    let payload = {
        local : $('.invoice-card .invoice-list').data('invoice'),
        invoices : [],
        tenant : $('.invoiced input[name="account_tenant"]').val()
    };

    $('.invoice-card .invoice-list tr[data-invoice]').each(function() {
        payload.invoices.push($(this).data('invoice'));
    })

    return;

    $('.invoice-card .card-loader-wrapper').addClass('card-loader-active');

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    $.ajax({
        url: "/manage/xero/updates",
        type: 'post',
        dataType: 'json',
        data: payload,
        success: function (result) {

            $('meta[name="csrf-token"]').attr('content', result.token);

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': result.token
                }
            });

            $('.invoice-list table tbody').html('');

            for (const row of result.table) {
                $('.invoice-list table tbody').append($(row));
            }

            $('.invoice-card .card-loader-wrapper').removeClass('card-loader-active');
            feather.replace();

        },
        error: function (xhr, status, error) {
            // This will be general errors //
        }
    });

};

function fetchEmails() {
    $('.email-list').each(function() {
        let $this = $(this);
        $.ajax({
            url: $(this).data('src'),
            type: 'get',
            success: function (result) {
                $this.html(result);
                $('.mail-count').text($this.find('.email-list-item').length);
            }
        });
    });
}

function sendEmail() {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    $('#orderEmails form textarea[name="body"]').val(tinyMCE.get('salesOrderEmailBody').getContent());

    let $data = $("#orderEmails form").serialize();

    $('#orderEmails button, #orderEmails select, #orderEmails input').prop('disabled', true);
    $('#orderEmails').addClass('ajaxSaving');

    $.ajax({
        url: $('#orderEmails .composer').attr('action'),
        type: 'post',
        dataType: 'json',
        data: $data,
        success: function (result) {

            $('meta[name="csrf-token"]').attr('content', result.csrf);
            $('form input[name="_token"]').val(result.csrf);

            $('#orderEmails').removeClass('ajaxSaving');

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': result.csrf
                }
            });

            // Re-enable all the buttons //
            $('#orderEmails button, #orderEmails select, #orderEmails input').prop('disabled', false);

            // Now we need to filter the errors to make sure its all good //
            if (result.status === 'success') {
                fetchEmails();
                $('#orderEmails [data-mode="sent"]').trigger('click');
                $('.composer')[0].reset();
                tinyMCE.get('salesOrderEmailBody').setContent('');

            } else {
                // Handle all the errors //
                alert(errors.join("\n"));
            }

        },
        error: function (xhr, status, error) {
            // This will be general errors //
        }
    });
}

$('.order-show-emails').on('click', function (e) {

    $('#orderEmails [data-mode="sent"]').trigger('click');
    $('#orderEmails').modal('show');

    tinymce.init({
        selector: '#salesOrderEmailBody',
        height: 300,
        menubar: false,
        default_text_color: 'red',
        plugins: [
            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
        ],
        toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | forecolor backcolor emoticons | codesample help',
        content_css: []
    });
});

let targetMode = null;
let toggleMode = function () {

    if ($('#orderEmails').hasClass('ajaxSaving')) return;

    $('#orderEmails .inbox-wrapper').removeClass(function (index, className) {
        return (className.match(/(^|\s)mode-\S+/g) || []).join(' ');
    }).addClass('mode-' + targetMode.data('mode'));

    $('#orderEmails .inbox-wrapper .nav-item').removeClass('active');
    targetMode.parent().addClass('active');
};

$('#orderEmails .inbox-wrapper a[data-mode]').on('click', function () {

    targetMode = $(this);

    if ($(this).data('mode') === 'compose' && checkUpdates()) {
        // Let them know the current order has been changed //
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-dark me-2',
                denyButton: 'btn btn-success me-2',
            },
            buttonsStyling: false,
        })

        swalWithBootstrapButtons.fire({
            title: 'Changes found..',
            text: "You have unsaved changes to your Sales Order.\n\nYou should Update the Sales Order before sending a Confirmation Email.",
            icon: 'warning',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Send Anyway',
            cancelButtonText: 'Ok, got it.',
            denyButtonText: 'Update Order',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                toggleMode();
            } else if (result.isDenied) {
                $('#sales-order button.submit').trigger('click');
            }
        })
    } else {
        toggleMode();
    }
});

$('#orderEmails .composer').on('submit', function (e) {
    e.preventDefault();
    sendEmail();
});

$('body').on('click', '.email-list-detail', function(e) {
    e.preventDefault();
    $.ajax({
        url: $(this).attr('href'),
        type: 'get',
        success: function (result) {
            $('#orderEmails .reading').html(result);

            $('#orderEmails .inbox-wrapper').removeClass(function (index, className) {
                return (className.match(/(^|\s)mode-\S+/g) || []).join(' ');
            }).addClass('mode-reading');

            $('#orderEmails .inbox-wrapper .nav-item').removeClass('active');

        }
    });
})

fetchEmails();
